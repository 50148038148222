import { useLocation, Navigate } from "react-router-dom";
import { useAuthentication } from "src/hooks/useAuthentication";

export function RequireAuthentication({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const { user } = useAuthentication();

  if (user !== undefined) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return children;
}

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import type { User } from "src/types";
import SignInView from "./signIn.view";

const defaultValues = {
  email: "",
  password: "",
};

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
});

export default function SignInController() {
  const form = useForm<User>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  return <SignInView form={form} />;
}

import { Image, useColorMode } from "@chakra-ui/react";

import Logo from "src/assets/logo/gamification-labs-logo.svg";
import LogoAlt from "src/assets/logo/gamification-labs-logo-alt.svg";

export default function Brand({ size }: { size?: number }) {
  const { colorMode } = useColorMode();
  return (
    <Image
      src={colorMode === "light" ? Logo : LogoAlt}
      w={size || 250}
      alt="Gamification Labs"
    />
  );
}

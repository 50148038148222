import { Box, SimpleGrid } from "@chakra-ui/react";

export default function SignUpView() {
  return (
    <Box>
      <SimpleGrid columns={[1, 2]} minH="100vh" overflowY="scroll">
        <Box bg="gray.50"></Box>
        <Box></Box>
      </SimpleGrid>
    </Box>
  );
}

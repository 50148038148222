import { Routes, Route, Navigate } from "react-router-dom";

import Loading from "src/components/loading";
import { RequireAuthentication } from "src/guards/RequiredAuthentication";
import { useAuthentication } from "src/hooks/useAuthentication";
import { Unauthorised } from "src/pages/401";
import { NoMatch } from "src/pages/404";

import SignIn from "src/containers/authentication/signIn/signIn.model";
import SignUp from "src/containers/authentication/signUp/signUp.model";
import ForgotPassword from "src/containers/authentication/forgetPassword/forgetPassword.model";
import ResetPassword from "src/containers/authentication/resetPassword/resetPassword.model";
import Onboarding from "src/containers/onboarding/onboarding.model";
import Dashboard from "src/containers/dashboard/dashboard.model";

const privateRoutes: any = [
  {
    path: "/",
    element: <Dashboard />,
  },
];

export default function AppRoutes() {
  const { isLoading, user } = useAuthentication();

  if (isLoading) return <Loading />;

  return (
    <>
      {user?.id !== undefined ? (
        <>
          {user?.business_id !== undefined ? (
            <Routes>
              <Route path="/onboarding" element={<Navigate replace to="/" />} />
              <Route path="/sign-in" element={<Navigate replace to="/" />} />
              <Route path="/sign-up" element={<Navigate replace to="/" />} />

              {/* Phone Pe payment status route */}
              {/* <Route path="/payment-status" element={<PaymentStatus />} /> */}

              {privateRoutes.map((privateRoute: any) => (
                <Route
                  key={privateRoute.path}
                  path={privateRoute.path}
                  element={
                    <RequireAuthentication>
                      {privateRoute.element}
                    </RequireAuthentication>
                  }
                />
              ))}
              <Route path="*" element={<NoMatch />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/" element={<Navigate replace to="/onboarding" />} />
              <Route
                path="/onboarding"
                element={
                  <RequireAuthentication>
                    <Onboarding />
                  </RequireAuthentication>
                }
              />
            </Routes>
          )}
        </>
      ) : (
        <Routes>
          <Route path="/" element={<Navigate replace to="/sign-in" />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Unauthorised />} />
        </Routes>
      )}
    </>
  );
}

import { useNavigate } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import { Button, Flex, Text } from "@chakra-ui/react";

export function Unauthorised() {
  const navigate = useNavigate();

  return (
    <Flex w="100%" h="100vh" direction="column" justify="center" align="center">
      <Text my="2rem" fontSize="2xl">
        <b>Error 401</b> : Unauthorised.
      </Text>
      <Button
        size="sm"
        variant="link"
        colorScheme="brand"
        leftIcon={<RiArrowLeftLine />}
        onClick={() => navigate("/sign-in")}
      >
        Sign In
      </Button>
    </Flex>
  );
}

import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
  sm: "40em",
  md: "52em",
  lg: "64em",
  xl: "80em",
};

const theme = extendTheme({
  styles: {
    global: {
      "html, body": {},
    },
  },
  fonts: {
    body: "'Poppins', sans-serif",
    heading: "'Poppins', sans-serif",
  },
  colors: {
    brand: {
      50: "#d4dffe",
      100: "#a8befc",
      200: "#678efa",
      300: "#3c6df9",
      400: "#265df8",
      500: "#2254df",
      600: "#1e4ac6",
      700: "#1b41ae",
      800: "#173895",
      900: "#132f7c",
    },
  },
  breakpoints,
});

export default theme;

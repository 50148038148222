/**
 * ----------------------------------------------------------------------
 *  NPM MODULES START
 *
 */

import * as React from "react";
import { useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

/**
 *
 *  NPM MODULES END
 * ----------------------------------------------------------------------
 */

/**
 * ----------------------------------------------------------------------
 *  CUSTOM MODULES START
 *
 */

import type { Authentication, User } from "src/types";
import { cookies } from "src/utils/cookie";
import { getUserById } from "src/services/user";
import {
  signUp,
  signIn,
  forgotPassword,
  resetPassword,
} from "src/services/authentication";

/**
 *
 *  CUSTOM MODULES END
 * ----------------------------------------------------------------------
 */

type AuthProviderType = {
  children: React.ReactNode;
};

const maxAge = 60 * 60 * 24 * 60;

export const AuthContext = React.createContext<Authentication>(null!);

export default function AuthProvider({ children }: AuthProviderType) {
  /**
   * ----------------------------------------------------------------------
   *  LIBRARY HOOKS START
   *
   */
  const toast = useToast();
  /**
   *
   *  LIBRARY HOOKS END
   * ----------------------------------------------------------------------
   */

  /**
   * ----------------------------------------------------------------------
   *  CUSTOM HOOKS START
   *
   */
  const id: string = cookies.get("user_id");
  /**
   *
   *  CUSTOM HOOKS END
   * ----------------------------------------------------------------------
   */

  /**
   * ----------------------------------------------------------------------
   *  LOCAL STATES START
   *
   */
  const [user, setUser] = React.useState<User>();
  const [isLoading, setLoading] = React.useState<boolean>(true);

  /**
   *
   *  LOCAL STATES END
   * ----------------------------------------------------------------------
   */

  /**
   * ----------------------------------------------------------------------
   *  REACT QUERY START
   *
   */
  const { isLoading: userFetching, data } = useQuery({
    queryKey: ["getUserById", id],
    queryFn: () => getUserById(id),
    retry: 0,
  });
  /**
   *
   *  REACT QUERY END
   * ----------------------------------------------------------------------
   */

  /**
   * ----------------------------------------------------------------------
   *  LOCAL EFFECTS START
   *
   */

  React.useEffect(() => {
    function checkUser() {
      if (userFetching === false) {
        setLoading(false);
        if (data && id) {
          setUser(data);
        } else {
          setUser(undefined);
        }
      }
    }
    checkUser();
  }, [data, id, userFetching]);
  /**
   *
   *  LOCAL EFFECTS END
   * ----------------------------------------------------------------------
   */

  /**
   * ----------------------------------------------------------------------
   *  HANDLER FUNCTIONS START
   *
   */

  async function userSignUp(value: User, callback: VoidFunction) {
    try {
      const { message } = await signUp(value);

      if (message === "success") {
        toast({
          title: "Registration Successful.",
          description: "Login to access your account.",
          status: "success",
        });
        callback();
      }
    } catch (error: any) {
      toast({
        title: "Registration Failed.",
        description: error?.message,
        status: "error",
      });
    }
  }

  async function userSignIn(value: User, callback: VoidFunction) {
    try {
      const { user, access_token, refresh_token } = await signIn(value);

      if (user) {
        setUser(user);
        cookies.set("user_id", user?.id, { maxAge });
        cookies.set("access_token", access_token, { maxAge });
        cookies.set("refresh_token", refresh_token, { maxAge });

        toast({
          description: "Login success.",
          status: "success",
        });
        callback();
      }
    } catch (error: any) {
      toast({
        description: error.message,
        status: "error",
      });
    }
  }

  async function userSignOut(callback: VoidFunction) {
    // const refreshToken: Token = cookies.get('refresh_token')

    try {
      // await logout(refreshToken)
      cookies.set("access_token", undefined);
      cookies.set("user_id", undefined);
      setUser(undefined);
      callback();
    } catch (error: any) {}
  }

  async function userForgotPassword(value: any, callback: VoidFunction) {
    try {
      const { status } = await forgotPassword(value);

      if (status === 201) {
        toast({
          title: "Email Sent Successfully.",
          description: "Check your email to reset password.",
          status: "success",
        });
        callback();
      }
    } catch (error: any) {
      toast({
        description: error.message,
        status: "error",
      });
    }
  }

  async function userResetPassword(
    value: string,
    jwtToken: string,
    callback: VoidFunction
  ) {
    try {
      const { status } = await resetPassword({ password: value }, jwtToken);

      if (status === 201) {
        toast({
          title: "Password Reset Successful.",
          description: "Login to access your account.",
          status: "success",
        });
        callback();
      }
    } catch (error: any) {
      toast({
        description: error.message,
        status: "error",
      });
    }
  }

  /**
   *
   *  HANDLER FUNCTIONS END
   * ----------------------------------------------------------------------
   */

  const value = {
    user,
    isLoading,
    userSignUp,
    userSignIn,
    userSignOut,
    userForgotPassword,
    userResetPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

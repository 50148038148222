import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { RiMailCheckLine, RiMailOpenLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Brand from "src/components/brand";
import useThemeMode from "src/hooks/useThemeMode";
import AuthBackground from "src/assets/images/auth_background.webp";

export default function SignInView(props: any) {
  const { mode20, mode70 } = useThemeMode();
  /**
   * ----------------------------------------------------------------------
   *  LIBRARY HOOKS START
   *
   */

  const { formState, control, handleSubmit } = props.form;
  const { errors } = formState;

  /**
   *
   *  LIBRARY HOOKS END
   * ----------------------------------------------------------------------
   */

  return (
    <Box>
      <SimpleGrid columns={[1, 2]} minH="100vh" overflowY="scroll">
        <Box
          bg={mode20}
          color="#fff"
          display={["none", "flex"]}
          bgImage={AuthBackground}
          bgSize="cover"
        >
          <Stack px={[6, 24]} py={[12, 24]} spacing={14}>
            <Text fontSize={["xl", "5xl"]} fontWeight={600}>
              Accelerate Business Growth with AI Cloud Solutions.
            </Text>
            {/* <List fontSize={"sm"} spacing={8}>
              <ListItem>
                <Stack direction="row" align="start" spacing={2}>
                  <ListIcon
                    as={RiCheckboxCircleFill}
                    color="brand.500"
                    fontSize={"18px"}
                  />
                  <Text mt={"-4px"}>
                    Rewards tailored to align with your company values and
                    culture, ensuring rewards resonate with employees.
                  </Text>
                </Stack>
              </ListItem>
              <ListItem>
                <Stack direction="row" align="start" spacing={2}>
                  <ListIcon
                    as={RiCheckboxCircleFill}
                    color="brand.500"
                    fontSize={"18px"}
                  />
                  <Text mt={"-4px"}>
                    Real-time data on engagement levels, participation rates,
                    and performance metrics.
                  </Text>
                </Stack>
              </ListItem>
            </List> */}
            <Text fontSize={["sm", "xl"]} fontWeight={500}>
              400+ businesses trusts us to manage and grow their business.
            </Text>
            <Stack direction={"row"} spacing={4}>
              <Flex bg={mode70} p={2} rounded={0}>
                <RiMailOpenLine size={24} />
              </Flex>
              <Stack spacing={0}>
                <Text fontSize={"sm"} fontWeight={500}>
                  Support Email
                </Text>
                <Text fontSize={"sm"} fontWeight={500}>
                  support@gamificationlabs.com
                </Text>
              </Stack>
            </Stack>
            <Stack direction={"row"} spacing={4}>
              <Flex bg={mode70} p={2} rounded={0}>
                <RiMailCheckLine size={24} />
              </Flex>
              <Stack spacing={0}>
                <Text fontSize={"sm"} fontWeight={500}>
                  Sales Email
                </Text>
                <Text fontSize={"sm"} fontWeight={500}>
                  sales@gamificationlabs.com
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <form onSubmit={handleSubmit(props.handleSignIn)}>
            <Stack px={[6, 24]} py={24} spacing={6} align="center">
              <Brand size={300} />
              <Text mb={6}>Sign in to access your account.</Text>
              <FormControl w={["100%", "60%"]} isInvalid={!!errors.email}>
                <FormLabel fontSize="sm">Email</FormLabel>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder="Enter your email"
                      rounded={0}
                      fontSize="sm"
                    />
                  )}
                />
                <FormErrorMessage>{errors.password?.email}</FormErrorMessage>
              </FormControl>

              <FormControl w={["100%", "60%"]} isInvalid={!!errors.password}>
                <FormLabel fontSize="sm">Password</FormLabel>
                <Controller
                  control={control}
                  name="password"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="password"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder="Enter your password"
                      rounded={0}
                      fontSize="sm"
                    />
                  )}
                />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>

              <Button
                type="submit"
                w={["100%", "60%"]}
                colorScheme="brand"
                rounded={0}
                isLoading={props.isLoading}
              >
                Sign in
              </Button>
              {/* <Link to="/forgot-password">
                <Text color="brand.600" fontSize="sm" fontWeight={500}>
                  Forgot password ?
                </Text>
              </Link> */}

              <Divider w={["100%", "60%"]} />

              {/* <Button
                w={["100%", "60%"]}
                colorScheme="blue"
                rounded={0}
                leftIcon={<RiLinkedinFill />}
              >
                Sign in with Linkedin
              </Button> */}

              <Stack direction={"row"} my={2} align={"center"}>
                <Text fontSize="sm">Do not have an account ?</Text>
                <Link
                  to="https://www.gamificationlabs.com/contact-us"
                  target="_blank"
                >
                  <Text fontSize="sm" color="brand.600" fontWeight={500}>
                    Contact us
                  </Text>
                </Link>
              </Stack>
            </Stack>
          </form>
        </Box>
      </SimpleGrid>
    </Box>
  );
}
